<template>
  <div class="">
    <QuizellButton
    v-if="!editMode"
      class="text-capitalization"
      @click="
        () => {
          error={
            imageUrl:false,
            title:false
          }
          imageUrl='';
          collectionTitle=''
          $refs.Modal.open();
        }
      "
      >+ Add {{ textCapitalization.singular }}</QuizellButton
    >
    <Modal
      :ref="refName"
      :id="`add${categoryTitle.singular}-${refName}`"
      :title="ModalTitle"
      size="md">
      <!-- <div class="d-flex align-items-center justify-content-end">
        <div class="mr-2">Image upload via:</div>
        <b-form-radio-group
        v-model="imageSelectRadio"
      id="file-upload"
      :options=" [
        { text: 'URL', value: 'url' },
        { text: 'File', value: 'file' },
      ]"
     
      name="radios-btn-default"
      buttons
      class="file-upload-option"
    ></b-form-radio-group>
      </div> -->
      <div class="px-5">
        <form @submit.prevent="save()">
        <div class="row">
          <div
            class="col-12 my-3"
            v-if="false && categoryTitle.singular == 'collection'">
            <div
              class="d-flex flex-column justify-content-center align-items-center">
              <UploadImage
                ref="uploadImage"
                :is-upload="true"
                @change="changeImage($event)"
                :upload-image="checkImage"></UploadImage>
              <div
                class="mt-2"
                :class="[
                  imageSelectRadio == 'file'
                    ? 'upload-image'
                    : 'upload-image-disabled ',
                ]"
                @click="
                  () => {
                    imageSelectRadio == 'file'
                      ? $refs.uploadImage.inputUploadFile()
                      : '';
                  }
                ">
                Upload Image
              </div>
            </div>
          </div>
          <div class="col-12 ">
            <label for="" style="color: #73738d; font-size: 14px"
              >{{ textCapitalization.singular }} Title:</label
            >
                 <div class="d-flex quizell-type justify-content-center align-items-center">
        <input type="text" :disabled="imageSelectRadio == 'file'" class="w-100 quizell-form-control px-2"  :placeholder="`${textCapitalization.singular} title`"  v-model.trim="collectionTitle"
  >
        
    </div>
           
            <span
              class="text-danger py-1"
              style="font-size: 12px"
              v-if="error.title"
              >Title field is required</span
            >
          </div>
          <div class="col-12" v-if="categoryTitle.singular == 'collection'">
            <div class="d-flex align-items-center justify-content-between">
              <label for="" style="color: #73738d; font-size: 14px" class="mt-2"
                >Collection Image URL:</label
              >
              <b-form-checkbox
                switch
                size="lg"
                v-model.trim="imageSelectRadio"
                value="url"
                unchecked-value="file"
                v-if="false"
                class="custom-switch"></b-form-checkbox>
            </div>
             <div class="d-flex quizell-type justify-content-center align-items-center">
        <input type="text" :disabled="imageSelectRadio == 'file'" class="w-100 quizell-form-control px-2"  placeholder="Collection Image URL"  v-model.trim="imageUrl"
  >
        
    </div>
           
            <span
              class="text-danger py-1"
              style="font-size: 12px"
              v-if="error.imageUrl"
              >Please provide valid URL.</span
            >
          </div>
          
        </div>
        <div class="d-flex mt-3 justify-content-center">
          <QuizellButton :isLoading="isLoading" type="submit">{{editMode?'Update':'Save'}}</QuizellButton>
        </div>
        </form>
      </div>
    </Modal>
  </div>
</template>
<script>
import QuizellButton from "../../Layout/Button.vue";
import Modal from "../../Layout/Modal.vue";
// import axios from 'axios'

import UploadImage from "../../Account/components/UploadImage.vue";
// import FilterBox from '../../Layout/FilterBox.vue'
export default {
  props: {
    categoryTitle: {
      type: Object,
      default: () => {
        return { singular: "", plural: "" };
      },
    },
    editMode:{
     type:Boolean,
     default:false
    },
     isLoading:Boolean
  },
  components: {
    QuizellButton,
    UploadImage,
  
    // FilterBox,
    Modal,
  },

  data() {
    return {
      imageUrl: "",
      imageSelectRadio: "url",
      collectionTitle: "",
      image: "",
      error: {
        title: false,
        imageUrl: false,
      },
    };
  },
  computed: {
    ModalTitle(){
      return this.editMode?`Update ${this.textCapitalization.singular} `:`Add ${this.textCapitalization.singular} Manually`
    },
    refName(){
     return  this.editMode?'updateModal':'Modal'
    },
    checkImage() {
      return this.imageUrl
        ? this.imageUrl
        : "https://images.quizell.com/website/default_start_page.png";
    },
    textCapitalization() {
      let { singular, plural } = this.categoryTitle;
      return {
        singular: singular.charAt(0).toUpperCase() + singular.slice(1),
        plural: plural.charAt(0).toUpperCase() + plural.slice(1),
      };
    },
  },
  methods: {
    open(data=''){
if(data){
  this.imageUrl=data.image
  this.collectionTitle=data.title
}

 this.$refs[this.refName].open();
    },
    close(){
 this.$refs[this.refName].close();
    },
    changeImage(newImage) {
      this.image = newImage;
    },
    validation() {
      /* eslint-disable-next-line */
      // const urlRegex = new RegExp(
      //   /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
      // );

      // if (this.categoryTitle.singular == "collection" && (!urlRegex.test(this.imageUrl)&&this.imageUrl!=''))this.error.imageUrl = true;
      // else this.error.imageUrl = false;
      
      if (this.collectionTitle.trim() == "") this.error.title = true;
     else this.error.title = false;
    },
   async save() {
     this.validation()
      if ((!this.error.title)&&(!this.error.imageUrl)) {
 
        this.error = {
          title: "",
          imageUrl: "",
        };
        const data={
          title: this.collectionTitle,
          image: this.imageUrl,
        }
      
        this.$emit('submit',data)
       
      }
    },
  },
};
</script>
<style scoped>
.checkbox >>> .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #ffa201;
  background-color: #ffa201 !important;
}
.checkbox >>> .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem #ffa201;
}
.loader {
  min-height: 40px;
}
.customPagination >>> li > button {
  color: #4d1b7e;
}

.customPagination >>> li.active > button {
  color: white;
  background-color: #4d1b7e !important;
  border-color: #4d1b7e;
}
.customPagination >>> li > button:hover {
  color: white;
  background-color: #6b4690 !important;
  border-color: #4d1b7e;
}
.upload-image {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  color: #ffa201;
  font-size: 14px;
  cursor: pointer;
}
.upload-image-disabled {
  color: #73738d;
  font-size: 14px;
}
.file-upload-option >>> .btn {
  background-color: #7a4fa3;
  padding: 6px 8px;
}
.file-upload-option >>> .active,
.file-upload-option >>> .btn:focus,
.file-upload-option >>> .btn:active {
  background-color: #4d1b7e !important;
}
.file-upload-option >>> .active:focus {
  box-shadow: 0 0 0 0.2rem #4d1b7e !important;
}
.custom-switch
  >>> .custom-control-input:checked
  ~ .custom-control-label::after {
  background-color: #ffa201 !important;
  border-color: #ced3d7 !important;
}
.custom-switch
  >>> .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #e0dee2 !important;
  border-color: #ced3d7 !important;
}

.custom-control-input.radio:checked ~ .custom-control-label::before {
  background-color: #0397cc !important;
  color: #0397cc !important;
  border-color: #0397cc !important;
}
.quizell-type:focus-within {
    color: #495057;
  background-color: #fff;
  border-color: #f7f6f9;
  outline: 0;
  box-shadow: 0 0 0 0.1rem #4d1b7e;
}
.quizell-form-control{
border: none;
height:48px;
flex: 1;
border-radius: 10px;
}
.quizell-form-control:focus-visible{
    border: none!important;
    outline: none!important;
}
.quizell-type{
    /* white */
transition: 0.3s ease all;
background: #FFFFFF;
/* greyLight */

border: 1px solid #D2D8E0;
border-radius: 8px;
}
</style>
